@use "../../../../scss/index.scss" as *;

#page_mission {
  width: 100%;
  background-color: color("secondaryLight");
  padding-top: 4rem;
  .mission-image {
    width: 100%;
    height: 100%;
    padding-bottom: 64px;
    object-fit: cover;
  }
  .mission-image-right {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 55%;
    padding-bottom: 64px;
  }
}
