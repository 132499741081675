@use "../../scss/index.scss" as *;
@use "sass:color";

#layout-home {
  overflow: hidden;
  #navigation {
    position: fixed;
    width: 100%;
    height: 56px;
    z-index: 1;
    background-color: transparent;
    left: 0;
    top: 0;
    padding: $large $large;
    z-index: 10;

    a {
      color: color("light");
      text-decoration: none;
    }

    transition: all 400ms cubic-bezier(0.075, 0.82, 0.165, 1);

    &.static-nav {
      #logo path,
      #menu-icon path {
        fill: color("light");
      }
    }

    &.scroll-nav {
      background-color: color("secondaryLight");
      box-shadow: 0px 4px 4px rgb(0 0 0 / 5%);
      a {
        color: color("primary");
      }
      #menu-icon path {
        fill: color("dark");
      }
    }
  }
}

// Mobile
#mobile-nav {
  width: 100vw;
  height: 100%;
  overflow: hidden;
  background-color: color.adjust(color("primary"), $lightness: 70%);
  position: relative;

  button {
    position: absolute;
    left: $base;
    top: $base;
  }

  a {
    width: 100%;
    @include h3;
    color: color("dark");
    text-decoration: none;
    text-transform: uppercase;
    text-align: right;
    padding: $medium $large $medium 0;

    &:hover {
      background-color: color("primary");
      color: color("light");
    }
  }
}
