@use "../../../../scss/index.scss" as *;
@use "sass:map";

#page_footer {
  width: 100%;
  min-height: 200px;
  height: auto;
  background: color("primary");
  display: flex;
  align-items: center;
  justify-content: center;
  padding: $xxlarge 0;
  position: relative;

  #footer_background_leaf_top_left {
    height: 50%;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0.75;

  }

  #footer_background_leaf_top_right {
    height: 50%;
    position: absolute;
    right: 0;
    top: 0;
    opacity: 0.75;

  }

  #footer_background_leaf_bottom_right {
    height: 50%;
    position: absolute;
    right: 0;
    bottom: 0;
    opacity: 0.75;
  }

  #footer_background_leaf_bottom_left {
    height: 50%;
    position: absolute;
    left: 0;
    bottom: 0;
    opacity: 0.75;
  }
}
