@use "../../scss/index.scss" as *;
@use "sass:color";

.MuiButton-overrides.MuiButton-root {
  // Reset
  box-shadow: none;
  border-radius: $fullRadius;
  text-transform: none;
  padding: 8px 12px;
  @include body2;

  // Sizing
  &.MuiButton-sizeSmall {
    height: 24px;
  }
  &.MuiButton-sizeMedium {
    height: 32px;
  }
  &.MuiButton-sizeLarge {
    height: 40px;
  }

  // Contained Styles
  &.MuiButton-containedPrimary {
    background-color: color("primary");

    &:hover {
      background-color: lighten(color("primary"), 10%);
    }
  }

  &.MuiButton-containedSecondary {
    background-color: color("secondary");
    color: color("dark");
  }

  // Outlined Styles
  &.MuiButton-outlinedPrimary {
    background-color: transparent;
    border-color: color("primary");
    color: color("primary");

    &:hover {
      background-color: color("primary");
      color: color("light");
    }
  }

  // Text Styles
  &.MuiButton-textPrimary {
    color: color("primary");
  }
}
