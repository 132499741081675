@use "../../../../scss/index.scss" as *;
@use "sass:map";


#page_hero {
  width: 100%;
  height: 100vh;
  position: relative;

  @media (min-width: 900px) {
    .show-mobile-only {
      display: none !important;
    }
  }

  #hero-image-mobile {
    padding-bottom: 16px;
  }

  #hero_blend {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #49584d;
    z-index: -1;
  }

  #hero_background_image {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    mix-blend-mode: multiply;
    object-fit: cover;
    z-index: -1;
  }

  #hero_video_card {
    position: absolute;
    right: -2rem;
    bottom: -4.5rem;
    width: 363px;
    height: 120px;
    background-color: color("primary");
    border-radius: $largeRadius;

    .left-section {
      border-right: 2px solid color("light");
    }
  }

  #socials {
    position: absolute;
    left: 0;
    bottom: $large;

    @include MaxBreakpoint(map.get($breakpoints, "md")) {
      left: 50%;
      transform: translate(-50%);
    }
  }
}
