@use "../../../../scss/index.scss" as *;
@use "sass:color";

#page_sponsors {
  width: 100%;
  min-height: 85vh;
  background-color: color("secondaryLight");
  padding: 6rem 0;

  @media (min-width: 900px) {
    #sponsors_image_container {
      text-align: center;
    }
  }

  #sponsors_image_container {
    width: 100%;
    height: 100%;
    .main_image {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}
