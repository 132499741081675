@use "../../../../scss/index.scss" as *;
@use "sass:map";

#page_values {
  width: 100%;
  min-height: 356px;
  height: auto;
  background: color("primary");
  display: flex;
  align-items: center;
  justify-content: center;
  padding: $xxlarge 0;
  position: relative;

  #values_background_leaf_top_left {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0.75;

    @include MaxBreakpoint(map.get($breakpoints, "mobile")) {
      width: 40%;
    }
  }

  #values_background_leaf_top_right {
    position: absolute;
    right: 0;
    top: 0;
    opacity: 0.75;
    @include MaxBreakpoint(map.get($breakpoints, "mobile")) {
      width: 30%;
    }
  }

  #values_background_leaf_bottom_right {
    position: absolute;
    right: 0;
    bottom: 0;
    opacity: 0.75;
    @include MaxBreakpoint(map.get($breakpoints, "mobile")) {
      width: 20%;
    }
  }

  #values_background_leaf_bottom_left {
    position: absolute;
    left: 0;
    bottom: 0;
    opacity: 0.75;
    @include MaxBreakpoint(map.get($breakpoints, "mobile")) {
      width: 25%;
    }
  }
}
