// Font Families
$primaryFontFamily: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
  sans-serif;
$secondaryFontFamily: "Source Serif Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
  "Helvetica Neue", sans-serif;

@mixin h1 {
  font-family: $primaryFontFamily;
  font-weight: 600;
  font-size: 3rem;
  line-height: 3.5rem;
  margin: 0;
}

@mixin h2 {
  font-family: $primaryFontFamily;
  font-weight: 600;
  font-size: 2rem;
  line-height: 2rem;
  margin: 0;
}

@mixin h3 {
  font-family: $primaryFontFamily;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 1.5rem;
  margin: 0;
}

@mixin h4 {
  font-family: $primaryFontFamily;
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.125rem;
  margin: 0;
}

@mixin h5 {
  font-family: $primaryFontFamily;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1rem;
  margin: 0;
}

@mixin body1 {
  font-family: $primaryFontFamily;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.25rem;
  margin: 0;
}

@mixin body2 {
  font-family: $primaryFontFamily;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25rem;
  margin: 0;
}

@mixin body3 {
  font-family: $primaryFontFamily;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.25rem;
  margin: 0;
}

@mixin body4 {
  font-family: $primaryFontFamily;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.25rem;
  margin: 0;
}

@mixin caption1 {
  font-family: $primaryFontFamily;
  font-weight: 500;
  font-size: 0.625rem;
  line-height: 0.75rem;
  margin: 0;
  letter-spacing: 0.025rem;
}

@mixin caption2 {
  font-family: $primaryFontFamily;
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 1rem;
  margin: 0;
  letter-spacing: 0.025rem;
}

@mixin caption3 {
  font-family: $primaryFontFamily;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1rem;
  margin: 0;
  letter-spacing: 0.025rem;
}

@mixin link {
  font-family: $primaryFontFamily;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.25rem;
  margin: 0;
  text-decoration: underline;
  cursor: pointer;
}
